import { getAnalytics, logEvent } from "firebase/analytics";
import { today } from "@/helpers/utils";

export function setFirebaseEvent(
  main_domain,
  event_name,
  clicked_where,
  email
) {
  const analytics = getAnalytics();

  logEvent(analytics, event_name, {
    main_domain,
    clicked_where,
    date: today,
    email,
  });
}
